///////////////////////////////////////////////////////////////////////////////
// jquery.glympser.js : Glympser jQuery plug-in
// (c) Glympse Inc., 2010-2014
//
// http://glympse.com
// Contact: http://support.glympse.com
///////////////////////////////////////////////////////////////////////////////

// For IE
(window.console || (window.console = { log: function () { } }));


(function ($, g, window, document, undefined)
{
	var urlParams = {};
	(function () {
		var e, a = /\+/g, r = /([^&=]+)=?([^&]*)/g, d = function (s) { return decodeURIComponent(s.replace(a, " ")); }, q = window.location.search.substring(1);
		while ((e = r.exec(q))) urlParams[d(e[1])] = d(e[2]);
	})();

	//var cLabelHtml = "html";
	var cLabelInit = "Init";
	var cUndefined = typeof undefined;
	var cCatLoader = "loader";
	var cCanvas = "canvas";
	var cCss = "css";

	var ssCfg, ssCfgUrl;

	var _bc;
	var sampleRate = 17;

	// Plug-in: instantiate a new Glympser control in the specified tag (i.e. <div>) in the HTML
	$.fn.glympser = function (options)
	{
		function generatePaths(base, cfg)
		{
			cfg.pathCss = base + 'css/glympser.min.css';
			cfg.pathAltCss = base + 'css/glympser-alt.min.css';
			cfg.pathSS = base + 'images/spritesheet/';
			cfg.pathHtmlCanvas = base + 'glympser.canvas.min.js';
			cfg.pathHtmlCss = base + 'glympser.css.min.js';
		}

		function normalizeBasePath(path)
		{
			return (path.indexOf('//') === 0) ? (document.location.protocol + path) : path;
		}

		function trailingSlash(path)
		{
			return (!path || path.substr(-1) === '/') ? path : (path + '/');
		}

//		 window.loadedGmaps = function(e)
//		 {
//		 	console.log('LOADED GMAPS', e);
//		 };

		options.hostPath = trailingSlash(options.hostPath) || 'https://viewer.content.glympse.com/';
		//TODO: need further discussion, leave basePath as fullPath to avoid changes across whole app code
		options.basePath = trailingSlash(options.basePath) || (options.hostPath + 'components/glympse-viewer/7.0.3/');

		options.hereBasePath = trailingSlash(options.hereBasePath) || 'static/hereMaps/3.1.60.0/';
		// do not change if full external url is provided as "hereBasePath"
		if (!/^(https?:)?\/\//.test(options.hereBasePath))
		{
			options.hereBasePath = (options.hostPath + options.hereBasePath);
		}

		options.hostPath = normalizeBasePath(options.hostPath);
		options.basePath = normalizeBasePath(options.basePath);
		options.hereBasePath = normalizeBasePath(options.hereBasePath);

		// console.info('>>> hostPath=', options.hostPath);
		// console.info('>>> basePath=', options.basePath);
		// console.info('>>> hereBasePath=', options.hereBasePath);

		// Instance global settings
		var keyGmaps = urlParams['gmapsKey'] || options.gmapsKey || options.idGmaps || 'AIzaSyAlhFRdYSK3ymjuM5V0i357rFUfB33lWJM';	// Google Maps - Business -- Glympse Viewer Default Key (12/20/2016)
		var componentName = 'GlympseViewerWeb';
		var componentVersion = '7.0.3';

		var localDefaults = {
			services: 'https://api.glympse.com/v2/'
//							, mapGMaps: "//maps.googleapis.com/maps/api/js?callback=loadedGmaps" + ((keyGmaps) ? ("&key=" + keyGmaps) : "")
			, mapGMaps: 'https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing&callback=parseInt' + ((keyGmaps) ? ('&key=' + keyGmaps) : '')
			//TODO: probably better to use basePath + "static/hereMaps/7/..."?
			, mapCore: (options.hereBasePath + 'mapsjs-core.js')
			, mapService: (options.hereBasePath + 'mapsjs-service.js')
			, mapEvents: (options.hereBasePath + 'mapsjs-mapevents.js')
			, mapHarp: (options.hereBasePath + 'mapsjs-harp.js')
			, w: 800
			, h: 600
			, screenOnly: false			// Set viewer in mode for taking snapshots
			, baseScale: 2.0
			, intro: g.Intro
			, profiles: g.Profiles.profiles
			, users: g.Profiles.users
			, appName: componentName
			, appVersion: componentVersion
			, controlsPadding: [0, 0, 0, 0]
		};

		generatePaths(options.basePath, localDefaults);

		// Set up reference for generated viewer
		options.appId = ("glympse" + g.viewer.cnt);
		g.viewer.cnt++;

		// External param handling
		var i, p;
		var defAllowLocalLang = "allowLocalLang";
		var extParams = [{ n: "altView", f: isBool }			// Simple alternate display
			, { n: "altUi", f: isBool }				// Use alternate UI theme
			, { n: "apiKey", f: String }			// Use alternate API key
			, { n: 'appName', f: String }			// Use alternate app name
			, { n: 'appVersion', f: String }		// Use alternate app version
			, { n: "authToken", f: String }			// Passed viewer auth token (environment dependent!)
			//, { n: "autoSize", f: isBool }		// Allow for internal viewport sizing updates
			, { n: "balloonMode", f: Number }		// Avatar balloon settings: -1 = no balloon, 0 = full, 1 = name-only, 2 = name + avatar, 3 = eta (simple)
			, { n: 'baseControl', f: String }		// Base map control provider: "here" = HERE Maps provider. Anything else = Google Maps.
			, { n: "broadcast", f: String }			// Flags to raise various events. See glympse.broadcastTypes for more info
			, { n: "broadcastData", f: isBool }		// [Deprecated] Flag to raise PROPERTIES/DATA events when data comes in on the data stream for an invite
			, { n: "botDestRadius", f: Number }		// `quasi_destination` with given radius will be generated for demo-bots instead of regular `destination` property
			, { n: "botDestShuffle", f: isBool }	// Flag to shuffle bot destinations (default = true)
			, { n: "botStartOffset", f: Number }	// Value between 0 and 0.9 to determine the amount of already-generated trails for demobots when launched
			, { n: "botTimeScale", f: Number }		// Time scale to run bot animations. Higher = faster. Defaults to 1.0 (normal time).
			, { n: "dbgGroup", f: Number }			// Group debugging
			, { n: "dbgNotify", f: isBool }			// Console debug info
			, { n: "dbgPaddings", f: isBool }		// Display map-centering padding info
			, { n: "dbgPosition", f: isBool }		// Display debug position info
			, { n: defAllowLocalLang, f: isBool }	// Use local browser language settings
			, { n: "defaultZoom", f: Number }		// Default zoom level for single POI display
			, { n: "destMode", f: Number }			// Destination label settings: 0 = dest name, 1 = eta (simple)
			, { n: "disableAddressLookup", f: isBool } // Disables reverse geo-code lookup of a destination's latLng position
			, { n: "disableFrameUpdate", f: isBool }// Disables 60fps mode (always)
			, { n: "disableRafTimeout", f: isBool }	// Disable using RAF for setTimeout calls
			, { n: "disableScaling", f: isBool }	// Disable auto-scaling of user icon based on current zoom level
			, { n: "disableSummary", f: isBool }	// Disable showing initial summary
			, { n: "disableCloudFront", f: isBool }	// Disable the use of CloudFront and hit S3 directly for resources
			, { n: "etaDisableQuery", f: isBool }	// Disable ETA query when missing from data stream
			, { n: "extCfg", f: String }			// External theming configuration GUID
			, { n: "etaTrailMode", f: String }		// ETA trail display mode: 'active', 'all', 'none' -- anything else = 'active'
			, { n: "forceFrameUpdate", f: isBool }	// Force doFrameUpdate in some scenarios (i.e. group view)
			, { n: "fuzzRatio", f: Number }			// Enable area fuzzing of invite positioning. 0 = disabled. Valid values should be between > 0 and < 1.
			, { n: "hereScheme", f: String }		// Scheme settings for HERE maps
			, { n: "expiredPeriodRemove", f: Number } // Timeout for expired glympses (autoremove)
			, { n: "hideDialog", f: Number }		// Flags to hide certain types of dialogs (1:No Location, 2:Expired, 4:Invalid Invite, 8:Empty Group)
			, { n: "includeEtaTrail", f: Number }	// Include user's current ETA trail for map centering (0 = no, >0 = yes, for active user)
			, { n: "includeTrail", f: Number }		// Include trail for map centering (0 = no, >0 = yes, for active user)
			, { n: "lang", f: String }				// Manual language override
			, { n: "mapLocked", f: isBool }			// Lock map from interaction
			, { n: "mapProvider", f: String }		// Map provider to use for tile display
			, { n: "mapType", f: String }			// Map type to display
			, { n: "noCount", f: String }			// Comma-delimited list of invite codes to not count as a "view", use "*" to include all invites
			, { n: "noHeaders", f: boolOrString }	// Legacy shortcut to disabling UI headers
			, { n: "pacman", f: String }			// Animated Pacman demo
			, { n: "screenOnly", f: isBool }		// Enable screenshot mode
			, { n: "services", f: String }			// Change default environment (https://api.glympse.com/v2/)
			, { n: "showAltitude", f: isTrueOrInt }	// Display of altitude metric (replaces ETA info)
			, { n: "showControls", f: isTrueOrInt }	// Map control visibility
			, { n: "showFuzzUserIcon", f: isBool }	// Show user icon in area fuzz display
			, { n: "showShadows", f: Number }		// Toggle to display shadows on top/bottom
			, { n: "showTraffic", f: isBool }		// Flag to display traffic overlays by default
			, { n: "trailLength", f: Number }		// User trail length (0 = show all)
			, { n: "useBatch", f: isBool }			// (Experimental!) Use batching for invite requests, can be helpful when big number of invites are added to the viewer at once
			, { n: 'watermarkBreakpoint', f: Number }// Breakpoint for larger attribution/watermark info (default = 320)
			, { n: 'hideUserDetails', f: isBool }   // Breakpoint for larger attribution/watermark info (default = 320)
			, { n: 'controlsPadding', f: isNumArray }   // Adds additional padding to controls. Supported formats: 5(number) - applies same padding to all controls; [5, 10]([top|bottom, left|right]); [5, 10, 15, 20]([top, right, bottom, left])
			, { n: 'hideSettingsControl', f: isBool }   // Adds additional padding to controls. Supported formats: 5(number) - applies same padding to all controls; [5, 10]([top|bottom, left|right]); [5, 10, 15, 20]([top, right, bottom, left])
		];

		var paramsQuery = {};	// Passed query string params -- used later in case of extCfg overrides
		for (i = extParams.length - 1; i >= 0; i--)
		{
			p = extParams[i];
			tmp = urlParams[p.n] || urlParams[p.n.toLowerCase()];
			if (typeof tmp !== cUndefined)
			{
				// Global special case
				if (p.n === 'disableCloudFront')
				{
					if (p.f(tmp))
					{
						g.assetBase = 'https://s3.amazonaws.com/static.glympse.com';
					}

					continue;
				}

				options[p.n] = p.f(tmp);
				paramsQuery[p.n] = options[p.n];

				// Force special cases
				if (p.n === 'lang')
				{
					options[defAllowLocalLang] = false;
					paramsQuery[defAllowLocalLang] = false;
				}
			}
		}

		var defProfile = g.Profiles.profiles[0];
		var uiTheme = g.uiTheme;
		var uiThemeAlternate = g.uiThemeAlternate;

		options.paramsQuery = paramsQuery;

		options.uiTheme = (options.altUi) ? $.extend(true, uiTheme, uiThemeAlternate, options.uiTheme)
			: $.extend(true, uiTheme, options.uiTheme);

		//FIXME: Do we need a value for this any more? If not, either needs to be set to empty or
		g.cssEx = options.altUi ? ' altUi ' : '';

		$.extend(true, g, options.uiTheme.global);
		$.extend(true, defProfile, options.uiTheme.defProfile);

		g.altUi = options.altUi;

		var opts = $.extend({}, localDefaults, g.defaults, options);
		opts.paramsHosted = options;	// Passed hosted params -- used later in case of extCfg overrides
		// FIXME: We now have two copies of the uiTheme floating around.. necessary??

		// Keep a reference to base map provider available to all parts of the app
		_bc = (opts.baseControl !== 'gmaps');	// --> boolean switch for now.. default to opt-in, initially
		g._bc = _bc;

		// For now, allow for internal defaults
		if (!opts.apiKey)
		{
			// Use an API key that works in both data centers
			opts.apiKey = 'Aacc5JPMx35CUmyw';	// sand/prod: application/74 --> prod org 20715 or 24778
		}

		if (!_bc && !keyGmaps)
		{
			console.log('[ Missing Google Maps Credentials!! ]');
			console.error('Aborting viewer');
			return;
		}
		if (_bc && (!opts.hereApiKey)) {
			console.log('[ Missing HERE Maps API key!! ]');
			console.error('Aborting viewer');
			return;
		}

		var t = opts.t || urlParams['t'];

		// FIXME: get gmaps working at 60fps!!
		//if (!_bc && !options.disableFrameUpdate && !urlParams.disableFrameUpdate)
		//{
		//	opts.disableFrameUpdate = true;
		//}

		// Set up header stuff
		opts.requestHeaders = { 'X-GlympseAgent': ('app=' + opts.appName
												 + '&ver=' + opts.appVersion
												 + '&comp=' + componentName
												 + '&comp_ver=' + componentVersion)
							  };

		// Snapshot config path
		ssCfgUrl = urlParams.view || opts.view;

		// User panel toggle via query string param
		var tmp = urlParams.showFullView;
		if (tmp)
		{
			tmp = isTrueOrInt(tmp);
			opts.showFullView = (tmp) ? ((tmp !== true) ? tmp : 1) : 0;
		}

		// Legacy setting from old glympse.com
		if (opts.noHeaders)
		{
			opts.hideApp = true;
			if (opts.noHeaders !== "app")
			{
				opts.showHeader = false;
			}
		}

		// FIXME: Temp hack for alternate display
		if (opts.altView)
		{
			opts.showHeader = false;
			opts.showShadows = 15;		// 1:top, 2:bottom, 4:left, 8:right
		}

		// Global override of timer handling
		if (opts.disableRafTimeout)
		{
			window.rafTimeout = window.setTimeout;
			window.clearRafTimeout = window.clearTimeout;
		}

		// noEtaTrail is deprecated in place of etaTrailMode
		if (opts.noEtaTrail)
		{
			opts.etaTrailMode = 'none';
		}
		else if (opts.etaTrailMode !== 'none' && opts.etaTrailMode !== 'all')
		{
			opts.etaTrailMode = 'active';
		}

		g.lib.initTracking(opts.gaTrackingId);

		$.extend(true, g.Loc.Langs, opts.uiTheme.loc);

		// Allow for resetting default text, if it exists and is not redefined in the current uiTheme.loc, or allowed to skip
		var locCull = opts.uiTheme.locCull;
		var len, lang;
		if (locCull)
		{
			var strings = locCull.strings;
			var uiThemeLoc = opts.uiTheme.loc;
			var gLocLangs = g.Loc.Langs;
			for (i = 0, len = strings.length; i < len; i++)
			{
				for (lang in gLocLangs)
				{
					var langStrings = gLocLangs[lang].strings;
					if (!langStrings || (locCull.skip && locCull.skip.indexOf(lang) >= 0))
					{
						continue;
					}

					var str = strings[i];
					if (!uiThemeLoc[lang] || !uiThemeLoc[lang].strings[str])
					{
						delete(langStrings[str]);
					}
				}
			}
		}

		var loc = new g.Loc((opts.lang || opts.defaultLang), opts.allowLocalLang);
		opts.currLang = loc.curr;

		if (opts.services && opts.services.indexOf("//") === 0)
		{
			opts.services = 'https:' + opts.services;
		}

		this.each(function() {
			var loader = new g.HtmlLoader(opts, this);
			loader.loadViewer();
		});

		return this;
	};

	function isBool(val)
	{
		return (val && val !== '0' && val.toLowerCase() !== 'false') || false;
	}

	function isTrueOrInt(val)
	{
		return ((isNaN(val)) ? isBool(val) : Number(val));
	}

	function boolOrString(val)
	{
		var lower = String(val).toLowerCase();
		return (lower === '0' || lower === '1' || lower === 'false' || lower === 'true') ? isBool(val) : val;
	}

	function isNumArray(val) {
		if (!isNaN(val)) {
			return [val, val, val, val];
		}

		if (Array.isArray(val) && val.length === 2) {
			return [val[0], val[1], val[0], val[1]];
		}

		if (!val) {
			return [0, 0, 0, 0];
		}

		return val;
	}



	///////////////////////////////////////////////////////////////////////////////
	// HTML LOADER
	///////////////////////////////////////////////////////////////////////////////

	g.HtmlLoader = function (opts, divElement)
	{
		var onlyCanvas;
		var loadedGmaps = (!_bc) ? isGMapsLoaded() : true;
		var loadedMapCore = (_bc) ? isMapCoreLoaded() : true;
		var loadedMapHarp = (_bc) ? isMapHarpLoaded(): true;
		var loadedMapService = (_bc) ? isMapServiceLoaded() : true;
		var loadedMapEvents = (_bc) ? isMapEventsLoaded() : true;
		var loadedApp = (typeof g.Application !== cUndefined);
		var progress = new g.lib.Progress(divElement);
		var cProgress = g.progress;

		// Set up loader progress info, in order of general execution
		progress.registerItem(cProgress.Init, 3);
		progress.registerItem(cProgress.Canvas, 0);
		progress.registerItem(cProgress.Font, 0);
		progress.registerItem(cProgress.Finalize, 0);

		if (_bc)
		{
			progress.registerItem(cProgress.Core, (loadedMapCore) ? 3 : 0);
			progress.registerItem(cProgress.Svc, (loadedMapService) ? 3 : 0);
			progress.registerItem(cProgress.Harp, (loadedMapHarp) ? 3 : 0);
			progress.registerItem(cProgress.Events, (loadedMapEvents) ? 3 : 0);
		}
		else
		{
			progress.registerItem(cProgress.Core, (loadedGmaps) ? 3 : 0);
		}

		progress.registerItem(cProgress.App, (loadedApp) ? 3 : 0);

		if (ssCfgUrl)
		{
			progress.registerItem(null, 5);
			progress.registerItem(cProgress.ExtSsCfg, 0);
			progress.registerItem(null, 5);
		}

		progress.registerItem(null, -1);

		if (opts.extCfg)
		{
			progress.registerItem(cProgress.ExtCfg, 0);
		}

		progress.registerItem(cProgress.Invite, 0);
		//progress.registerItem(cProgress.Intro, 0);
		progress.registerItem(cProgress.Ss, 0);

		function isGMapsLoaded()
		{
			var isAvailable = ((typeof google !== cUndefined) && (typeof google.maps !== cUndefined) && (typeof google.maps.LatLng !== cUndefined));

			if (isAvailable)
			{
				var gm = google.maps;
				g.LatLng = gm.LatLng;
				g.LatLngBounds = gm.LatLngBounds;
				g.Polyline = gm.Polyline;
			}

			return isAvailable;
		}

		function isMapCoreLoaded()
		{
			var n = window.H;
			var isAvailable = ((typeof n !== cUndefined) && (typeof n.Map !== cUndefined) && (typeof n.geo !== cUndefined));// && (typeof n.maps.geo.Coordinate !== cUndefined));
			if (isAvailable)
			{
				var geo = n.geo;
				g.LatLng = geo.Point;
				g.LatLngBounds = geo.Rect;

				var LineString = geo.LineString;

				function GPolyline(ls, opts) {
					var that = this;
					if (ls.getPointCount() < 2) {
						ls = LineString.fromLatLngArray([0, 0, 0, 0]);
						this.initializedEmpty = true;
						this.internalGeometry = new LineString();
						this.internalGeometry.pushPoint = function (geoPoint) {
							LineString.prototype.pushPoint.call(this, geoPoint);

							if (this.getPointCount() > 1 && that.initializedEmpty) {
								that.setGeometry(this);
								that.initializedEmpty = false;
							}
						}
					}

					n.map.Polyline.call(this, ls, opts);
				}

				GPolyline.prototype = Object.create(n.map.Polyline.prototype);

				GPolyline.prototype.constructor = GPolyline;

				GPolyline.prototype.getGeometry = function () {
					if (this.initializedEmpty) {
						return this.internalGeometry;
					}

					return n.map.Polyline.prototype.getGeometry.call(this);
				}

				g.Polyline = GPolyline;
			}

			return isAvailable;
		}

		function isMapHarpLoaded()
		{
			var n = window.H;
			return (typeof n !== cUndefined) && (typeof n.map.render.harp !== cUndefined);
		}

		function isMapServiceLoaded()
		{
			var n = window.H;
			return ((typeof n !== cUndefined) && (typeof n.service !== cUndefined) && (typeof n.service.Platform !== cUndefined));
		}

		function isMapEventsLoaded()
		{
			var n = window.H;
			return ((typeof n !== cUndefined) && (typeof n.mapevents !== cUndefined) && (typeof n.mapevents.MapEvents !== cUndefined));
		}

		// Initialize a canvas context to use for feature detection
		var c;
		try
		{
			c = document.createElement(cCanvas);
			if (c)
			{
				c = c.getContext("2d");
				c.fillStyle = "#000000";
			}
			progress.updateProgressItem(cProgress.Canvas, 3);
		}
		catch (exp)
		{
			c = null;
			progress.updateProgressItem(cProgress.Canvas, 4);
		}

		this.loadViewer = function ()
		{
			opts.containerElement = divElement;
			//console.log("onlyCss=" + opts.onlyCss + ", canvas=" + opts.onlyCanvas);

			// Get scale regardless
			var hasCanvas = getDeviceScale();
			var forced = false;

			if (opts.onlyCanvas)
			{
				forced = true;
			}
			else if (opts.onlyCss || !hasCanvas || !hasProperCanvasSupport())
			{
				if (!opts.onlyCss)
				{
					console.log("css --> " + hasCanvas + " -- " + hasProperCanvasSupport());
				}
				finalizeInit(false);
				return;
			}
			else if (navigator.userAgent.match(/Windows Phone 7/i) || navigator.userAgent.match(/Windows Phone OS 7/i))
			{
				// WinPhone 7 has good canvas support, just no custom fonts
				forced = true;
			}

			var pre = "@font-face { font-family: 'Asap'; font-style: normal; font-weight: ";
			var urlBase = ", url(https://fonts.gstatic.com/s/asap/v5/";
			var post = ".woff2) format('woff2'); }\n";
			// Kick off font loading on canvas to verify final support
			$("head").prepend("<style type='text/css'>"
				//+ "@font-face { font-family: 'Asap'; font-style: italic; font-weight: 700; src: local('Asap Bold Italic'), local('Asap-BoldItalic'), url(http://themes.googleusercontent.com/static/fonts/asap/v1/HeYzwarLlBOP-vBnan8oPT8E0i7KZn-EPnyo3HZu7kw.woff) format('woff'); }"
				+ pre + "400; src: local('Asap'), local('Asap Regular'), local('Asap-Regular')" + urlBase + "fcmHFw2-Y5XBgckTELHEMQ" + post
				+ pre + "700; src: local('Asap Bold'), local('Asap-Bold')" + urlBase + "CR2dk1HR3x5VnkqjPj24Gg" + post
				//+ "@font-face { font-family: 'Asap'; font-style: italic; font-weight: 400; src: local('Asap Italic'), local('Asap-Italic'), url(http://themes.googleusercontent.com/static/fonts/asap/v1/B7s64e7hsAx1t-4QjcAo3g.woff) format('woff'); }"
				+ "</style>"
			);
			//console.log("fuB=" + urlBase);

			/*$('head').prepend('<link href="https://fonts.googleapis.com/css?family=Asap:400,700&amp;subset=latin-ext" rel="stylesheet">');*/

			// Force font availability
			function genElement(fontWeight)
			{
				$(divElement).prepend($(document.createElement('div'))
							 .css({ fontWeight:fontWeight, fontFamily:'Asap', position:'absolute', visibility:'hidden', height:1, width:1, fontSize:1 })
							 .text('ABCDEFGHIJKLMNOPQRSTUVWXYZ'));
			}

			genElement(400);
			genElement(700);

			if (forced)
			{
				finalizeInit(true);
				return;
			}

			checkFontLoad(12);
		};

		function finalizeInit(doCanvas)
		{
			//console.log("doCanvas = " + doCanvas);
			onlyCanvas = doCanvas;
			progress.updateProgressItem(cProgress.Finalize, (doCanvas) ? 3 : 4);

			// Load css viewer stylesheet
			if (!doCanvas)
			{
				// FIXME: Remove this once altview is combined with css viewer
				if (opts.altView)
				{
					opts.altView = false;
					opts.showHeader = true;
				}

				var cssPath = (g.altUi) ? opts.pathAltCss : opts.pathCss;
				// For IE < 9
				if (document.createStyleSheet)
				{
					document.createStyleSheet(cssPath);
				}
				else
				{
					$('<link>', { rel: 'stylesheet', type: 'text/css', href: cssPath }).appendTo('head');
				}
			}

			// Load all other .js components
			launchViewerApp();
		}

		function verifyGMapsLoaded()
		{
			if (!isGMapsLoaded()) return false;

			loadedGmaps = true;
			launchViewerApp();
			return true;
		}

		function verifyMapCoreLoaded()
		{
			if (!isMapCoreLoaded()) return false;

			loadedMapCore = true;
			launchViewerApp();
			return true;
		}

		function verifyMapHarpLoaded() {
			if (!isMapHarpLoaded()) return false;

			loadedMapHarp = true;
			launchViewerApp();
			return true;
		}

		function verifyMapServiceLoaded()
		{
			if (!isMapServiceLoaded()) return false;

			//trkHereMapsLoad.submit();
			loadedMapService = true;
			launchViewerApp();
			return true;
		}

		function verifyMapEventsLoaded()
		{
			if (!isMapEventsLoaded()) return false;

			//trkHereMapsLoad.submit();
			loadedMapEvents = true;
			launchViewerApp();
			return true;
		}

		function loadPlugin(id, url, callbackVerify, typeProgress)
		{
			progress.updateProgressItem(typeProgress, 1);

			//console.log("!!!LOADING " + id + " !!!");
			$.ajax({
				dataType: "script", cache: true, url: url, success: function (/*data*/)
				{
					if (!callbackVerify())
					{
						var timer = setInterval(function ()
						{
							var status = callbackVerify();
							progress.updateProgressItem(typeProgress, (status) ? 3 : 2);
							if (status) clearInterval(timer);
						}, 50);
					}
					else
					{
						progress.updateProgressItem(typeProgress, 3);
					}
				}
			});
		}

		function loadPlugins()
		{
			if (!loadedGmaps)
			{
				loadPlugin("CORE", opts.mapGMaps, verifyGMapsLoaded, cProgress.Core);
				return;
				/*				$.ajax({ dataType: 'script', cache: true, url: opts.mapGMaps, success: function (data)
				 {
				 if (!verifyGMapsLoaded())
				 {
				 var delay = 50;
				 var timer = setInterval(function ()
				 {
				 if (verifyGMapsLoaded())
				 {
				 clearInterval(timer);
				 }
				 }, delay);
				 }
				 }
				 });*/
			}

			//console.log("Need here? " + loadedHereMaps);
			if (!loadedMapCore)
			{
				loadPlugin("CORE", opts.mapCore, verifyMapCoreLoaded, cProgress.Core);
				return;
			}

			if (!loadedMapHarp)
			{
				loadPlugin("HARP", opts.mapHarp, verifyMapHarpLoaded, cProgress.Harp)
				return;
			}

			if (!loadedMapService)
			{
				loadPlugin("SERVICE", opts.mapService, verifyMapServiceLoaded, cProgress.Svc);
				return;
			}

			if (!loadedMapEvents)
			{
				loadPlugin("EVENTS", opts.mapEvents, verifyMapEventsLoaded, cProgress.Events);
				return;
			}

			if (!loadedApp)
			{
				progress.updateProgressItem(cProgress.App, 1);
				$.ajax({ dataType: "script", cache: true, url: ((onlyCanvas) ? opts.pathHtmlCanvas : opts.pathHtmlCss), success: function(/*data*/)
					{
						progress.updateProgressItem(cProgress.App, (onlyCanvas) ? 3 : 4);
						loadedApp = true;
						launchViewerApp();
					}
				});
			}

			if (ssCfgUrl)
			{
				progress.updateProgressItem(cProgress.ExtSsCfg, 1);
				$.support.cors = true;	// Not always supported
				$.ajax({  url: ssCfgUrl,
					dataType: "json"
					, cache: true
					//, crossDomain: true
					, success: function (data)
					{
						ssCfg = data;
						//console.log(ssCfgUrl + " -- got data:" + JSON.stringify(data));
						ssCfgUrl = null;
						progress.updateProgressItem(cProgress.ExtSsCfg, 3);
						launchViewerApp();
					}
					, error: function (xOptions, status)
					{
						console.log("Error loading ssCfg:" + status + ", url:" + ssCfgUrl);
						progress.updateProgressItem(cProgress.ExtSsCfg, 2);
						ssCfgUrl = null;
						launchViewerApp();
					}
				});
			}
		}

		function launchViewerApp()
		{
			if (!loadedMapCore) { console.log("x MapCore"); loadPlugins(); return false; }
			if (!loadedMapService) { console.log("x MapService"); loadPlugins(); return false; }
			if (!loadedMapEvents) { console.log("x MapEvents"); loadPlugins(); return false; }
			if (!loadedMapHarp) { console.log("x MapHarp"); loadPlugins(); return false; }
			if (!loadedGmaps) { console.log("x GMaps"); loadPlugins(); return false; }
			if (!loadedApp) { console.log("x App"); loadPlugins(); return false; }
			if (ssCfgUrl) { console.log("x ssCfg"); return false; }

			opts.preload || (opts.preload = ssCfg);			// Allow for preset preload to override snapshot config

			// Start the viewer
			opts.app = new g.Application();
			g.viewer.apps[opts.appId] = opts.app;
			//console.log("done!");
			opts._progress = progress;
			opts.app.init(opts);

			return true;
		}

		function getDeviceScale()
		{
			// Deal with browsers that lie about devicePixelRatio (i.e. FF on Android always = 1),
			// but can handle browsers that report accurate info (desktop browsers)
			var w = window;
			/*var sOutIn = w.outerHeight / w.innerHeight;*/
			var devicePixelRatio = w.devicePixelRatio;
			//opts.scale = (!devicePixelRatio || devicePixelRatio > 1) ? 2 : ((sOutIn > 1.5) ? sOutIn : 1);
			opts.scale = 2;//(!devicePixelRatio || devicePixelRatio > 1 || sOutIn > 1) ? 2 : 1;
			//console.log("scale=" + opts.scale);
			if (!c)
			{
				return false;
			}

			var backingStoreRatio = c.webkitBackingStorePixelRatio
				|| c.mozBackingStorePixelRatio
				|| c.msBackingStorePixelRatio
				|| c.oBackingStorePixelRatio
				|| c.backingStorePixelRatio; // || 1;
			//console.log("dev=" + devicePixelRatio + ", back=" + backingStoreRatio);
			// FIXME: WP7 doesn't appear to have any info on pixel ratio or backingstore
			// --> window.msMatchMedia also doesn't appear to work
			// --> All WP7 are 1.5x ratio though, so just need to detect if WP7 device....
			//console.log("isWinPhone:" + navigator.userAgent.match(/Windows Phone/i));
			//console.log("dev=" + devicePixelRatio + ", back=" + backingStoreRatio);
			if (!devicePixelRatio || opts.scale === 1)
			{
				opts.scale = (!backingStoreRatio && !devicePixelRatio) ? 1.5 : ((devicePixelRatio || 1) / (backingStoreRatio || 1));
				opts.scale = ((opts.scale > 1) ? 2 : 1); //((s > 1) ? 1.5 : 1));
			}

			return true;
		}

		function hasProperCanvasSupport()
		{
			// Early out for initial BB10.0 devices (possibly 10.1 has a workable canvas)
			if (navigator.userAgent.match(/BB10/i)) return false;

			// Crappy Samsung browsers only get css as well
			if (navigator.userAgent.match(/Chrome\/2[89]/i)) return false;

			var s = 30 * opts.scale;

			c.canvas.width = s;
			c.canvas.height = s;

			var gradient = c.createLinearGradient(0, 0, 0, s);
			gradient.addColorStop(0, "rgb(64, 32, 16)");
			gradient.addColorStop(1, "rgb(32, 16, 8)");
			c.font = "bold " + s + "pt"; // " Asap, Calibri, Arial";
			c.fillStyle = gradient;
			c.fillText("N", 0, s);

			var startR = 0;
			//var lastR = 0;
			//var startA = 0;
			//var sx = 0, ex = 0, ey = 0, sy = 0;
			var d = c.getImageData(0, 0, s, s);

			for (var x = 0; x < s; x++)
			{
				for (var y = s - 1; y >= 0; y--)
				{
					var z = 4 * (y * s + x);
					var a = d.data[z + 3];

					if (a > 0)
					{
						//sx = x;
						//sy = y;
						startR = d.data[z];
						x = s;
						break;
					}
					/*
					 if (a > 0 && startA == 0)//startR == 0 && r > 0)
					 {
					 sx = x;
					 sy = y;
					 startA = a;
					 startR = d.data[z];
					 }
					 else if (a == 0 && startA)//(r == 0 && startR)
					 {
					 ex = x;
					 ey = y;
					 x = s;
					 break;
					 }

					 if (a > 0)
					 {
					 lastR = d.data[z];
					 }

					 console.log("zzz r=" + d.data[z] + ", g=" + d.data[z + 1] + ", b=" + d.data[z + 2] + ", a=" + d.data[z + 3] + " -- z=" + z + ", x=" + x + ", y=" + y);
					 */
				}
			}

			//console.log("dR = " + (startR - lastR) + " -- start=" + startR);
			//return { s : startR, l : lastR, sx : sx, ex : ex, sy : sy, ey : ey };
			return (startR > 0);
		}

		function checkFontLoad(cnt, start)
		{
			var sz = 30;

			c.clearRect(0, 0, c.canvas.width, c.canvas.height);
			progress.updateProgressItem(cProgress.Font, 1);

			//console.log("check=" + cnt);
			var txt = "WWWWWWWWWWWWWWWWWSWW7WWWWWWWWWWWWWWWW";
			c.font = "bold " + sz + "pt sans-serif";
			var sans = c.measureText(txt).width;
			c.font = "bold " + sz + "pt Arial, sans-serif";
			var arial = c.measureText(txt).width;
			c.font = "bold " + sz + "pt Calibri, Arial, sans-serif";
			var calibri = c.measureText(txt).width;
			c.font = "bold " + sz + "pt Asap, Calibri, Arial, sans-serif";
			var asap = c.measureText(txt).width;

			/*var tag = '[' + cnt + ']>> ';
			var spc = '\n' + '           '.substr(0, tag.length);
			console.log(tag + 'sans   :' + sans + spc + 'arial  :' + arial + spc + 'calibri:' + calibri + spc + 'asap   :' + asap);
			*/

			if (asap < calibri && asap < arial && asap < sans)
			{
				if (start && start - cnt > 1)
				{
					console.log('>> fnt success: ' + ((start - cnt) + 1) + ' tries');
				}
				progress.updateProgressItem(cProgress.Font, 3);
				finalizeInit(true);
				return;
			}

			if (cnt <= 0)
			{
				console.log('cFL: fnt-chk expired (' + start + ' tries)');
				progress.updateProgressItem(cProgress.Font, 2);
				finalizeInit(false);
				return;
			}

			cnt--;
			progress.updateProgressItem(cProgress.Font, ((cnt % 2) === 0) ? 4 : 5);
			rafTimeout(function ()
			{
				checkFontLoad(cnt, start || (cnt + 1));
			}, 500);
		}
	};

})(jQuery, glympse, window, document);
